import * as React from "react";
import ArrowButton from "../../components/ArrowButton";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface AboutTestimonialsProps {}

const AboutTestimonials: React.FC<AboutTestimonialsProps> = ({}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const img1 = require("../../assets/Bevi-Quote.png");
  const mobileImg1 = require("../../assets/MobileAbtTestimonialsKK.png");
  const bg = require("../../assets/aboutTestimonialsBg.png");
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "98vw",
        height: phone ? "45rem" : tablet ? "fit content" : "50rem",
        paddingTop: tablet ? "5rem" : 0,
        minHeight: "fit-content",
        display: "flex",
        flexDirection: phone ? "column" : "row",
        justifyContent: phone ? "flex-start" : "center",
        alignItems: "center",
        margin: phone ? "1rem auto" : "3rem auto 0 auto",
        backgroundImage: `url(${bg})`,
        backgroundPosition: phone ? "center" : "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          width: phone ? "100%" : "90%",
          height: phone ? "fit-content" : "34rem",
          minHeight: "fit-content",
          display: "flex",
          flexDirection: phone ? "column" : "row",
          justifyContent: phone ? "space-between" : "center",
          margin: phone ? "2.5rem auto" : "2rem auto 0 auto",
        }}
      >
        {/* image */}

        <Box
          sx={{
            width: phone ? "100vw" : tablet ? "55%" : "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: phone ? "flex-start" : "center",
            alignItems: tablet ? "flex-start" : "center",
          }}
        >
          <Box
            sx={{
              height: phone ? "fit-content" : tablet ? "90%" : "90%",
              width: phone ? "100vw" : tablet ? "100%" : "90%",
              borderRadius: phone ? "1rem" : tablet ? "1.5rem" : "3rem",
              aspectRatio: phone ? "1/1.25" : "1/1.25",
              backgroundImage: `url(${phone || tablet ? mobileImg1 : img1})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: phone ? " 100% 100%" : "cover",
            }}
          ></Box>
        </Box>

        {/* text */}

        <Box
          sx={{
            width: phone ? "100%" : "50%",
            padding: phone
              ? "2rem 2rem 0 2rem "
              : tablet
              ? "0 0 0 2rem"
              : "5rem",
            textAlign: "left",
            height: "fit-content",
            minHeight: "fit-content",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginBottom: phone ? "1.5rem" : 0,
              minHeight: "fit-content",
              width: "100%",
            }}
          >
            <h1
              style={{
                fontSize: phone || tablet ? "1.75rem" : "2.5rem",

                width: "100%",
              }}
            >
              Be a part of something bigger at{" "}
              <span style={{ color: "#C97B99", fontStyle: "italic" }}>
                Herewith
              </span>
            </h1>
            <p
              style={{
                fontSize: "1rem",
                lineHeight: 1.5,
                marginTop: "1rem",
                marginBottom: "1.5rem",
                minHeight: "fit-content",
              }}
            >
              Join us in our mission to break down barriers to affordable,
              convenient support for older adults. 
              <br />
              <br />
              We bring the same honest, empathetic spirit to our work behind the
              scenes at Herewith headquarters in San Francisco as we do in our
              approach to care. 
            </p>
          </div>

          <ArrowButton text="Get in touch" link="/partners#getintouch" />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutTestimonials;

// “I want to make a difference in the field of Gerontology. At Herewith I can do just that, by pioneering new ways for older adults and their families to use technology and get the help they need.”
// – Bevi Kothare, VP of Business Development

// “Indeed there are only a few workplaces where you can truly touch the lives of others. Herewith allows me to do that everyday. I feel encouraged that my team can make a real difference to older adults and their families.”
// – Khurram Khan, COO

// “Herewith strengthens the bond between technology and human care, creating amazing outcomes for those we serve. Witnessing this synergy come to life has been one of the most rewarding experiences of my career.”
// – Veronica Baiz, Director of Operations

// “At Herewith, I blend my passion for UX, technology and human connection by creating enriching experiences, to provide a sense of belonging through a helping hand.”
// – Rudy Iyer, Product Manager

// “I simply want to make life easier for people like my mum and grandpa. We're building a service that helps everyone who's working hard to make sure our parents are living their best lives.”
// – Daniel Stanley, Head of Design
