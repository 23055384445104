import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import EventsHeader from "./EventsHeader.tsx";
import TrustedLocal from "./TrustedLocal.tsx";
import FastConvenient from "./FastConvenient.tsx";
import HelpYouNeed from "./HelpYouNeed.tsx";
import WeCanHelpEvents from "./WeCanHelpEvents.tsx";
import Testimonials from "../Dashboard/Testimonials.tsx";

interface GetStartedProps {}

const GetStarted: React.FC<GetStartedProps> = () => {
  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <EventsHeader />
        <FastConvenient />
        <TrustedLocal />
        <WeCanHelpEvents />
        <HelpYouNeed />
        {/* <EventsTestimonials /> */}
        <Testimonials />
        <Footer />
      </Box>
    </>
  );
};

export default GetStarted;
