// @ts-nocheck

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Box, TextField } from "@mui/material";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface GetinTouchProps {}

const GetinTouch: React.FC<GetinTouchProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const img1 = require("../../assets/getintouch.jpg");

  const [values, setValues] = useState({
    user_firstname: "",
    user_email: "",
    user_phone: "",
    user_lastname: "",
    user_company: "",
  });
  const [showform, setShowForm] = useState(true);

  // const {
  //   user_firstname,
  //   user_lastname,
  //   user_company,
  //   user_email,
  //   user_phone,
  // } = values;

  const handleChange = (name: any) => (e: any) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // console.table({
    //   user_firstname,
    //   user_lastname,
    //   user_company,
    //   user_email,
    //   user_phone,
    // });
    emailjs
      .send("service_0wpnajk", "template_jwgsn9q", values, "6RrTocq4cQC5EE_MT")
      .then(
        (result) => {
          setShowForm(false);
          setValues({
            user_firstname: "",
            user_email: "",
            user_phone: "",
            user_lastname: "",
            user_company: "",
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };
  return (
    <Box
      id="getintouch"
      sx={{
        width: "100%",
        maxWidth: "max-content",
        minWidth: "73%",
        maxHeight: "max-content",
        padding: phone ? "0 1rem" : "0 ",
        height: phone || tablet ? "fit-content" : "40rem",

        borderBottom: "1px solid lightgray",
        display: "flex",
        flexDirection: phone ? "column-reverse" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: phone ? "0 auto" : "3rem auto",
        paddingBottom: "2rem",
        // border: "1px solid red",
      }}
    >
      {/* text */}
      {showform && (
        <Box
          sx={{
            width: phone ? "100%" : "100%",
            padding: phone ? "0" : "2rem",
            textAlign: "left",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            marginRight: phone ? 0 : "1rem",
            maxHeight: "max-content",
          }}
        >
          <div>
            <h1
              style={{
                fontSize: phone ? "1.75rem" : "2.5rem",
                marginLeft: phone ? ".1rem" : 0,
              }}
            >
              Get in touch
            </h1>
            <p
              style={{
                marginLeft: phone ? ".2rem" : 0,
                marginTop: phone ? "1rem" : 0,
              }}
            >
              Looking to expand benefits for your employees? Let's get in touch!
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                height: "fit-content",
                display: "flex",
                flexDirection: "column",
                marginTop: "1.5rem",
                padding: 0,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <TextField
                  required
                  id="filled-helperText"
                  label="First name"
                  placeholder="Enter your first name"
                  variant="outlined"
                  type="text"
                  name="user_firstname"
                  value={values.user_firstname}
                  sx={{ margin: ".5rem", width: "50%" }}
                  onChange={handleChange("user_firstname")}
                />{" "}
                <TextField
                  required
                  id="filled-helperText"
                  label="Last name"
                  placeholder="Enter your last name"
                  variant="outlined"
                  type="text"
                  name="user_lastname"
                  value={values.user_lastname}
                  onChange={handleChange("user_lastname")}
                  sx={{ margin: ".5rem", width: "50%" }}
                />{" "}
              </Box>
              <TextField
                required
                id="filled-helperText"
                label="Company name"
                placeholder="Enter your Company name"
                variant="outlined"
                type="text"
                name="user_company"
                value={values.user_company}
                onChange={handleChange("user_company")}
                sx={{ margin: ".5rem" }}
              />{" "}
              <TextField
                required
                id="filled-helperText"
                label="Work email"
                placeholder="Enter your Work email"
                variant="outlined"
                type="text"
                name="user_email"
                value={values.user_email}
                onChange={handleChange("user_email")}
                sx={{ margin: ".5rem" }}
              />{" "}
              <TextField
                required
                id="filled-helperText"
                label="Phone number"
                placeholder="Enter your Phone number"
                variant="outlined"
                type="text"
                name="user_phone"
                value={values.user_phone}
                onChange={handleChange("user_phone")}
                sx={{ margin: ".5rem", marginBottom: phone ? "1.5rem" : 0 }}
              />{" "}
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "40%",
                  width: phone ? "100%" : "fit-content",
                  height: "3.5rem",
                  fontSize: "18px",
                  marginTop: "2rem",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {!showform && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            flex: 1,
            width: phone ? "100%" : "100%",
            padding: phone ? "0" : "2rem",
            textAlign: "center",
            height: "100%",
          }}
        >
          <h1
            style={{
              fontSize: phone ? "1.75rem" : "2.5rem",
              marginLeft: phone ? ".1rem" : 0,
              display: phone ? "none" : "block",
            }}
          >
            Get in touch
          </h1>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <ThumbUpIcon
              sx={{
                fontSize: phone ? "3rem" : "5rem",
                color: "#115c72",
                margin: phone ? "5rem 0 3rem 0" : 0,
              }}
            />
            <h2 style={{ marginBottom: phone ? "5rem " : 0 }}>
              Thank you for your submission!
            </h2>
          </Box>
        </Box>
      )}
      {/* image */}

      <Box
        sx={{
          height: phone ? "auto" : "auto",
          width: phone ? "100%" : "35vw",
          borderRadius: phone ? "2rem" : "3rem",
          aspectRatio: "1",
          backgroundImage: `url(${img1})`,
          backgroundPosition: phone ? "center top" : "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: phone ? "cover" : "cover",
          margin: phone ? "1rem" : 0,
        }}
      ></Box>
    </Box>
  );
};

export default GetinTouch;

// hello@herewith.com
