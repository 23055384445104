import "./App.css";

import Dashboard from "./views/Dashboard/Dashboard.tsx";
import About from "./views/AboutUs/About.tsx";
import CaregiverHome from "./views/CaregiverHomepage/CaregiverHome.tsx";
import HelpCenter from "./views/HelpCenter/HelpCenter.tsx";
import PrivacyPolicy from "./views/PrivacyPolicy.tsx";
import TermsOfService from "./views/TermsOfService.tsx";
import BennieHealth from "./views/PartnerPages/BennieHealth.tsx";
import CommunityGuidelines from "./views/CommunityGuidelines.tsx";
import BlogHome from "./views/HelpCenter/BlogHome.tsx";
import SingleBlogPost from "./views/HelpCenter/SingleBlogPost.tsx";
import EmployerLanding from "./views/Employers/EmployerLanding.tsx";
import EventsLanding from "./views/Events/EventsLanding.tsx";
import PartnerLanding from "./views/PartnerPages/PartnerLanding.tsx";
import GetStarted from "./views/Campaigns/GetStarted.tsx";
import Error from "./views/Error.tsx";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop.js";
import ScrollToAnchor from "./utils/ScrollToAnchor.js";

const App = () => {
  const theme = useTheme();
  // const phone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <ScrollToAnchor />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/helpers" element={<CaregiverHome />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/help-center" element={<HelpCenter />} />
          <Route exact path="/guides-and-articles" element={<HelpCenter />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/partners" element={<EmployerLanding />} />
          <Route exact path="/events" element={<EventsLanding />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/bennie-health" element={<BennieHealth />} />
          <Route exact path="/partner-demo" element={<BennieHealth />} />
          <Route exact path="/get-started" element={<GetStarted />} />
          <Route
            exact
            path="/member-benefits"
            element={<PartnerLanding partner="ebg" />}
          />
          <Route
            exact
            path="/benefits"
            element={<PartnerLanding partner="beneplace" />}
          />

          <Route exact path="/blog" element={<BlogHome />} />
          <Route path="/help-center/:slug" element={<SingleBlogPost />} />
          <Route path="*" element={<Error />} />
          <Route
            exact
            path="/community-guidelines"
            element={<CommunityGuidelines />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
