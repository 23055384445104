import * as React from "react";
import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface OurStoryProps {}

const OurStory: React.FC<OurStoryProps> = ({}) => {
  const img = require("../../assets/ourstory.png");
  const imgMobile = require("../../assets/MobileOurStoryBg.png");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        width: "100%",
        height: phone ? "80vh" : "38rem",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "row",
        justifyContent: phone
          ? "center"
          : tablet
          ? "flex-start"
          : "space-between",
        alignItems: "center",
        margin: phone ? "1rem auto" : "3rem auto",
        backgroundImage: `url(${phone ? imgMobile : img})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: phone ? 0 : tablet ? "3rem" : "5rem 5rem 5rem 10rem",
      }}
    >
      <Paper
        sx={{
          width: phone ? "90%" : tablet ? "65%" : "50%",
          minHeight: "fit-content",
          padding: phone ? "1rem" : tablet ? "3rem" : "4rem",
          borderRadius: phone ? "2rem" : "3rem",
          textAlign: phone ? "center" : "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <h1
          style={{
            fontSize: phone ? "1.75rem" : "2.5rem",
            marginBottom: phone ? "1rem" : "2rem",
            marginTop: phone ? "1rem" : "0",
          }}
        >
          Herewith's <i style={{ color: "#6AA08F" }}>story</i>
        </h1>
        {/* <p
          style={{ marginBottom: "1.5rem", fontSize: "1rem", lineHeight: 1.5 }}
        >
          We started with a mission to help our loved ones navigate the
          confusing world of in-home care. Now, we’ve grown into a nationwide
          platform for busy families, care workers, and care facilities.
        </p> */}
        <p style={{ marginBottom: "2rem", fontSize: "1rem", lineHeight: 1.5 }}>
          We started with a mission to help our loved ones navigate the
          confusing world of in-home care. Now, we’ve grown into a nationwide
          platform for busy families, care workers, and care facilities. We
          blend the ease of an all-in-one online platform with task-based
          booking, empowering families and Helpers to get exactly what they
          need.
          <br />
          <br />
          As the distinguished sole investor, BIG Capital JSC delivers
          unparalleled financial support and invaluable expertise, driving
          Herewith's success and significant market expansion.
        </p>
      </Paper>
    </Box>
  );
};

export default OurStory;
