import * as React from "react";
import { Box } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
interface PartnerBannerProps {}

const PartnerBanner: React.FC<PartnerBannerProps> = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const img3 = require("../../assets/partnerlogosbanner.png");
  const img3mobiletab = require("../../assets/partnerlogosmobile.png");

  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100%",
          overflowX: "hidden",

          minHeight: phone ? "0rem" : "20rem",
          height: "fit-content",
          maxWidth: "100vw",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingBottom: "2rem",
          backgroundColor: "#C0E4EB",
        }}
      >
        <h1
          style={{
            margin: tablet ? "2rem " : 0,
            fontSize: tablet ? "2rem" : "2.5rem",
          }}
        >
          Join our <i style={{ color: "#3D7A86" }}>partnerships</i> in care
        </h1>
        <img
          src={phone ? img3mobiletab : img3}
          alt="img"
          style={{ width: tablet ? "90%" : "75%" }}
        />
      </Box>
    </>
  );
};

export default PartnerBanner;
