import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
interface TrustedLocalProps {}

const TrustedLocal: React.FC<TrustedLocalProps> = ({}) => {
  const [utm, setUTM] = useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo1&utm_id=marketing"
  );
  const img = require("../../assets/TrustedLocal.png");
  const imgMobile = require("../../assets/MobileOurStoryBg.png");
  const imgTab = require("../../assets/TrustedLocalBgTablet.png");
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  // useEffect(() => {
  //   if (window.location.href.includes("?utm")) {
  //     setUTM(`?${window.location.href.split("?").pop()}`);
  //   }
  // }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: phone ? "85vh" : "38rem",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "row",
        justifyContent: phone ? "center" : "space-between",
        alignItems: "center",
        margin: phone ? "1rem auto" : "0 auto",
        backgroundImage: `url(${phone ? imgMobile : tablet ? imgTab : img})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: phone ? 0 : tablet ? "3rem" : "5rem ",
      }}
    >
      <Paper
        sx={{
          width: phone ? "90%" : tablet ? "50%" : "45%",
          height: "fit-content",
          padding: phone ? "1rem" : tablet ? "2.5rem" : "4rem",
          borderRadius: phone ? "2rem" : "3rem",
          textAlign: phone ? "center" : "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <h1
          style={{
            fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            marginBottom: phone ? "1rem" : "2rem",
            marginTop: phone ? "1rem" : "0",
          }}
        >
          Book trusted local <i style={{ color: "#1D99BE" }}>Helpers</i>
        </h1>
        <p
          style={{ marginBottom: "1.5rem", fontSize: "1rem", lineHeight: 1.5 }}
        >
          Chat with 100% background-checked, insured local Helpers on our
          platform to book help that seamlessly complements the services
          provided by senior living facilities.
        </p>
        <p style={{ marginBottom: "2rem", fontSize: "1rem", lineHeight: 1.5 }}>
          Looking to find that special Helper to connect with your loved one?
          Re-book the same Helper for one-time or recurring help.
        </p>
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
            sx={{
              width: "fit-content",
              height: "3rem",
              fontSize: "18px",
              backgroundColor: "#FDBB2E",
              color: "black",

              margin: phone ? "1rem auto" : "2rem 0 0 0",
              "&:hover": {
                background: "#FECA5B",
                // Add other hover styles as needed
              },
            }}
          >
            Find Helpers near you
          </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default TrustedLocal;
