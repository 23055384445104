import * as React from "react";

import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

interface ZeroCostProps {}

const ZeroCost: React.FC<ZeroCostProps> = () => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const img1 = require("../../assets/zerocoststats.png");
  const img1tablet = require("../../assets/zerocosttablet.png");
  const img1mobile = require("../../assets/zerocostmobile.png");

  return (
    <Box
      sx={{
        width: "100%",
        height: phone ? "fit-content" : "38rem",
        backgroundColor: "#FAF8F5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        margin: phone ? "0 auto" : "3rem auto",
        padding: "2rem",
      }}
    >
      <Box>
        <h1 style={{ display: tablet ? "none" : "block" }}>
          Zero cost benefits. High impact{" "}
          <i style={{ color: "#795EC6" }}>solution</i>
        </h1>
        <h1 style={{ display: tablet ? "block" : "none", fontSize: "2rem" }}>
          Zero cost benefits.
          <br /> High impact <i style={{ color: "#795EC6" }}>solution</i>
        </h1>
        <p style={{ marginTop: phone || tablet ? "1rem" : 0 }}>
          Here's why caregiving support for employees is a big deal for your
          business{" "}
        </p>
      </Box>
      <img
        src={phone ? img1mobile : tablet ? img1tablet : img1}
        alt="stats"
        style={{
          width: phone ? "100%" : tablet ? "100%" : "85%",
          marginTop: phone ? "2rem" : 0,
          marginBottom: phone ? "2rem" : 0,
        }}
      />
      <Link to="/partners#getintouch">
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
          sx={{
            width: phone ? "100%" : "fit-content",
            height: tablet ? "3rem" : "3.5rem",
            fontSize: "18px",

            "&:hover": {
              background: "#FECA5B",
              // Add other hover styles as needed
            },
          }}
        >
          Get in touch
        </Button>
      </Link>
    </Box>
  );
};

export default ZeroCost;
