import { hover } from "@testing-library/user-event/dist/hover";
import * as React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components";
import { useEffect, useState } from "react";

interface LinkProps {
  text: string;
  url: string;
  color: string;
}

const Navlink: React.FC<LinkProps> = ({ text, url, color }) => {
  const theme = useTheme();
  const [active, setActive] = useState(true);
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const styles = {
    textDecoration: "none",
    margin: tablet ? "0 .6rem" : "0 1rem",
    fontSize: tablet ? "1rem" : "1.125rem",
    textWrap: "nowrap",
    color:
      window.location.href.includes(url) && url !== "/"
        ? "#1D99BE"
        : window.location.href == "https://herewith.com/" && url == "/"
        ? "#1D99BE"
        : window.location.href == "http://localhost:3000/" && url == "/"
        ? "#1D99BE"
        : window.location.href == "https://herewith-staging.netlify.app/" &&
          url == "/"
        ? "#1D99BE"
        : color,
  };

  return (
    <Link style={styles} to={url}>
      {text}
    </Link>
  );
};

export default Navlink;
