import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CgPartners() {
  const curve = require("../assets/graycurve.png");
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const logos = require("../assets/partnerlogos.png");
  const mobilelogos = require("../assets/partnerlogosmobile.png");
  return (
    <>
      <Box
        sx={{
          width: "100%",

          display: phone ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "20rem",
          backgroundColor: " #FAF8F5        ",
        }}
      >
        <h1 style={{ fontSize: tablet ? "2rem" : "2.5rem" }}>Our partners</h1>
        <Box
          sx={{
            width: tablet ? "100%" : "80%",
            display: "flex",
            justifyContent: "space-evenly",

            marginTop: "2rem",
          }}
        >
          <img
            src={logos}
            alt=""
            style={{ maxWidth: "90% !important", width: "90%" }}
          />
        </Box>
      </Box>

      {/* ------- mobile -------- */}

      <Box
        sx={{
          width: "100%",

          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "30vh",
          backgroundColor: " #FAF8F5 ",
          borderBottom: "1px solid #EBE9E6 ",
        }}
      >
        <h1>Our partners</h1>
        <img
          src={mobilelogos}
          alt=""
          style={{
            width: "90%",
            margin: "1rem auto",
            maxWidth: "90% !important",
          }}
        />
      </Box>
    </>
  );
}
