import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import PartnerHeader from "./PartnerHeader.tsx";
import TrustedLocal from "./TrustedLocal.tsx";
import FastConvenient from "./FastConvenient.tsx";
import HelpYouNeed from "./HelpYouNeed.tsx";
import WeCanHelpEvents from "./WeCanHelpEvents.tsx";
import Testimonials from "../Dashboard/Testimonials.tsx";

interface PartnerLandingProps {
  partner: string;
}

const PartnerLanding: React.FC<PartnerLandingProps> = ({ partner }) => {
  return (
    <>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <PartnerHeader partner={partner} />
        <FastConvenient />
        <TrustedLocal partner={partner} />
        <WeCanHelpEvents partner={partner} />
        <HelpYouNeed />
        {/* <EventsTestimonials /> */}
        <Testimonials />
        <Footer />
      </Box>
    </>
  );
};

export default PartnerLanding;
